//import axios from 'axios';
import axios from '../configs/axiosConfig'
import { AppConfig } from '../configs/appConfig';

//const EMPLOYEE_API_BASE_URL = "http://localhost:8080/api/v1/employees";
const USER_API_BASE_URL = AppConfig.API_BASE_URL + "users";

class UsersService {

    getUsers() {
        return axios.get(USER_API_BASE_URL);
    }

    createUser(user) {
        return axios.post(USER_API_BASE_URL, user);
    }

    getUserById(userId) {
        return axios.get(USER_API_BASE_URL + '/' + userId);
    }

    updateUser(user, userId) {
        return axios.put(USER_API_BASE_URL + '/' + userId, user);
    }

    deleteUser(userId) {
        return axios.delete(USER_API_BASE_URL + '/' + userId);
    }

    getUsersInbox() {
        return axios.get(USER_API_BASE_URL + '/inbox');
    }

    getUsersRoles() {
        return axios.get(USER_API_BASE_URL + '/roles');
    }

    addUsers(newUserData) {
        return axios.post(USER_API_BASE_URL, newUserData);
    }

    updateUserInActive(userId) {
        return axios.put(USER_API_BASE_URL + '/inactive', userId);
    }

    UpdateUserActiveInactive(UserId, loginUserId, IsActive) {
        return axios.put(USER_API_BASE_URL + "/statuschange", { UserId, loginUserId, IsActive });
    }

    importUser(userData) {
        return axios.post(USER_API_BASE_URL + '/import', userData);
    }

    getLoginUserByEmailAndPassword(email, password) {
        return axios.get(USER_API_BASE_URL + '/emailandpassword',
            {
                params: {
                    Email: email,
                    Password: password,
                }
            });
    }
    getUsersByRoleName(roleName, isActive) {
        return axios.get(USER_API_BASE_URL + '/rolename', {
            params: {
                RoleName: roleName,
                IsActive: isActive
            }
        });
    }
    getCheckUserExistsByEmail(userId, emailId) {
        return axios.get(USER_API_BASE_URL + '/exists/email', {
            params: {
                UserId: userId,
                Email: emailId
            }
        });
    }

    getUserDetailByEmail(email) {
        return axios.get(USER_API_BASE_URL + '/email',
            {
                params: { Email: email }
            });
    }

    getCheckRolesByEmail(email) {
        return axios.get(USER_API_BASE_URL + '/inflow/email',
            {
                params: { Email: email }
            });
    } 
}

export default new UsersService()