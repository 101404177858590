import React, { useEffect } from 'react';
import { useMsal, useAccount } from '@azure/msal-react';
import { msalConfig } from "../../configs/msalConfig";


const AccessDenied = () => {
  const { instance } = useMsal();

  const handleLogoutClick = (instance)=>{
    const logoutUrl = msalConfig.auth.postLogoutRedirectUri;
    const logoutRequest = {
      account: instance.getActiveAccount(),
      postLogoutRedirectUri: logoutUrl,
    };
    instance.logoutRedirect(logoutRequest);  }

    
    return (
      <>
      <div
        // className="loginBox"
        style={{   height: '100vh' }}
      >
        <div className="d-flex align-items-center justify-content-center h-100">
          <div className="text-center text-danger">
            <h1 style={{
              fontSize:'210px',
              color: 'danger',
              fontWeight: 900,
              textShadow: '4px 4px 0 #fff, 6px 6px 0 #263238',
            }}>403</h1>
            <div className="my-3">
              <h4>ACCESS DENIED !</h4>
              <span className="text-muted d-block fs-5">
              You dont have permission to view this site.
              </span>
            </div>
            <button to="/" onClick={()=>handleLogoutClick(instance)} className="btn btn-primary">
              Back to Login
            </button>
          </div>
        </div>
      </div>
    </>

      
    );
  };
  
  export default AccessDenied;