import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import HeaderTranslationEN from './locales/Header/en.json';
import HeaderTranslationJA from './locales/Header/ja.json';
import HeaderTranslationAR from './locales/Header/ar.json';
import SidebarTranslationEN from './locales/Sidebar/en.json';
import SidebarTranslationJA from './locales/Sidebar/ja.json';
import SidebarTranslationAR from './locales/Sidebar/ar.json';
import UserTranslationsEN from './locales/Users/en.json';
import UserTranslationsJA from './locales/Users/ja.json';
import UserTranslationsAR from './locales/Users/ar.json';
import DashboardTranslationsEN from './locales/Dashboard/en.json';
import DashboardTranslationsJA from './locales/Dashboard/ja.json';
import DashboardTranslationsAR from './locales/Dashboard/ar.json';
import NotificationsTranslationsEN from './locales/Notifications/en.json';
import NotificationsTranslationsJA from './locales/Notifications/ja.json';
import NotificationsTranslationsAR from './locales/Notifications/ar.json';
import FundsTranslationsEN from './locales/Funds/en.json';
import FundsTranslationsJA from './locales/Funds/ja.json';
import FundsTranslationsAR from './locales/Funds/ar.json';
import CommonTranslationEN from './locales/Common/en.json';
import CommonTranslationJA from './locales/Common/ja.json';
import CommonTranslationAR from './locales/Common/ar.json';
import PropertiesTranslationEN from './locales/Properties/en.json';
import PropertiesTranslationJA from './locales/Properties/ja.json';
import PropertiesTranslationAR from './locales/Properties/ar.json';
import RolesTranslationEN from './locales/Roles/en.json';
import RolesTranslationJA from './locales/Roles/ja.json';
import RolesTranslationAR from './locales/Roles/ar.json';
import ServiceProviderTranslationEN from './locales/ServiceProvider/en.json';
import ServiceProviderTranslationJA from './locales/ServiceProvider/ja.json';
import ServiceProviderTranslationAR from './locales/ServiceProvider/ar.json';
import ViewUploadedFilesTranslationEN from './locales/ViewUploadedFiles/en.json';
import ViewUploadedFilesTranslationJA from './locales/ViewUploadedFiles/ja.json';
import ViewUploadedFilesTranslationAR from './locales/ViewUploadedFiles/ar.json';
import DatasetTranslationEN from './locales/Dataset/en.json';
import DatasetTranslationJA from './locales/Dataset/ja.json';
import DatasetTranslationAR from './locales/Dataset/ar.json';
import ConfigureWorkflowTranslationEN from './locales/ConfigureWorkflow/en.json';
import ConfigureWorkflowTranslationJA from './locales/ConfigureWorkflow/ja.json';
import ConfigureWorkflowTranslationAR from './locales/ConfigureWorkflow/ar.json';
import DatasetMappingTranslationEN from './locales/DatasetMapping/en.json';
import DatasetMappingTranslationJA from './locales/DatasetMapping/ja.json';
import DatasetMappingTranslationAR from './locales/DatasetMapping/ar.json';
import PropertyMappingTranslationEN from './locales/PropertyMapping/en.json';
import PropertyMappingTranslationJA from './locales/PropertyMapping/ja.json';
import PropertyMappingTranslationAR from './locales/PropertyMapping/ar.json';
import COAMasterTranslationEN from './locales/COAMaster/en.json';
import COAMasterTranslationJA from './locales/COAMaster/ja.json';
import COAMasterTranslationAR from './locales/COAMaster/ar.json';
import COAMappingTranslationEN from './locales/COAMapping/en.json';
import COAMappingTranslationJA from './locales/COAMapping/ja.json';
import COAMappingTranslationAR from './locales/COAMapping/ar.json';
import RulesEngineTranslationEN from './locales/RulesEngine/en.json';
import RulesEngineTranslationJA from './locales/RulesEngine/ja.json';
import RulesEngineTranslationAR from './locales/RulesEngine/ar.json';
import ManageFrequencyTranslationEN from './locales/ManageFrequency/en.json';
import ManageFrequencyTranslationJA from './locales/ManageFrequency/ja.json';
import ManageFrequencyTranslationAR from './locales/ManageFrequency/ar.json';
import IntegrationCenterTranslationEN from './locales/IntegrationCenter/en.json';
import IntegrationCenterTranslationJA from './locales/IntegrationCenter/ja.json';
import IntegrationCenterTranslationAR from './locales/IntegrationCenter/ar.json';
import LookupKeyTranslationEN from './locales/SystemMaster/LookupKey/en.json';
import LookupKeyTranslationJA from './locales/SystemMaster/LookupKey/ja.json';
import LookupKeyTranslationAR from './locales/SystemMaster/LookupKey/ar.json';
import LookupValueTranslationEN from './locales/SystemMaster/LookupValue/en.json';
import LookupValueTranslationJA from './locales/SystemMaster/LookupValue/ja.json';
import LookupValueTranslationAR from './locales/SystemMaster/LookupValue/ar.json';
import GlossaryTranslationsEN from './locales/Glossary/en.json';
import GlossaryTranslationsJA from './locales/Glossary/ja.json';
import GlossaryTranslationsAR from './locales/Glossary/ar.json';

// Function to initialize i18n
const initializei18n = (language) => {
  i18n
    .use(initReactI18next)
    .init({
      resources: {
        en: {
          translation: {
            Header: HeaderTranslationEN,
            Sidebar: SidebarTranslationEN,
            Users:UserTranslationsEN,
            Dashboard: DashboardTranslationsEN,
            Notifications: NotificationsTranslationsEN,
            Funds:FundsTranslationsEN,
            Common:CommonTranslationEN,
            properties: PropertiesTranslationEN,
            Roles: RolesTranslationEN,
            ServiceProvider : ServiceProviderTranslationEN,
            ViewUploadedFiles : ViewUploadedFilesTranslationEN,
            Dataset : DatasetTranslationEN,
            ConfigureWorkflow: ConfigureWorkflowTranslationEN,
            DatasetMapping: DatasetMappingTranslationEN,
            PropertyMapping:PropertyMappingTranslationEN,
            COAMaster: COAMasterTranslationEN,
            COAMapping: COAMappingTranslationEN,
            RulesEngine: RulesEngineTranslationEN,
            ManageFrequency: ManageFrequencyTranslationEN,
            IntegrationCenter: IntegrationCenterTranslationEN,
            LookupKey: LookupKeyTranslationEN,
            LookupValue: LookupValueTranslationEN,
            Glossary: GlossaryTranslationsEN
          },
        },
        ja: {
          translation: {
            Header: HeaderTranslationJA,
            Sidebar: SidebarTranslationJA,
            Users:UserTranslationsJA,
            Dashboard: DashboardTranslationsJA,
            Notifications: NotificationsTranslationsJA,
            Funds:FundsTranslationsJA,
            Common:CommonTranslationJA,
            properties: PropertiesTranslationJA,
            Roles: RolesTranslationJA,
            ServiceProvider : ServiceProviderTranslationJA,
            ViewUploadedFiles : ViewUploadedFilesTranslationJA,
            Dataset : DatasetTranslationJA,
            ConfigureWorkflow: ConfigureWorkflowTranslationJA,
            DatasetMapping: DatasetMappingTranslationJA,
            PropertyMapping:PropertyMappingTranslationJA,
            COAMaster: COAMasterTranslationJA,
            COAMapping: COAMappingTranslationJA,
            RulesEngine: RulesEngineTranslationJA,
            ManageFrequency: ManageFrequencyTranslationJA,
            IntegrationCenter: IntegrationCenterTranslationJA,
            LookupKey: LookupKeyTranslationJA,
            LookupValue: LookupValueTranslationJA,
            Glossary: GlossaryTranslationsJA
          },
        },
        ar: {
          translation: {
            Header: HeaderTranslationAR,
            Sidebar: SidebarTranslationAR,
            Users:UserTranslationsAR,
            Dashboard: DashboardTranslationsAR,
            Notifications: NotificationsTranslationsAR,
            Funds: FundsTranslationsAR,
            Common:CommonTranslationAR,
            properties: PropertiesTranslationAR,
            Roles: RolesTranslationAR,
            ServiceProvider : ServiceProviderTranslationAR,
            ViewUploadedFiles : ViewUploadedFilesTranslationAR,
            Dataset : DatasetTranslationAR,
            ConfigureWorkflow: ConfigureWorkflowTranslationAR,
            DatasetMapping: DatasetMappingTranslationAR,
            PropertyMapping:PropertyMappingTranslationAR,
            COAMaster: COAMasterTranslationAR,
            COAMapping: COAMappingTranslationAR,
            RulesEngine: RulesEngineTranslationAR,
            ManageFrequency: ManageFrequencyTranslationAR,
            IntegrationCenter: IntegrationCenterTranslationAR,
            LookupKey: LookupKeyTranslationAR,
            LookupValue: LookupValueTranslationAR,
            Glossary: GlossaryTranslationsAR
          },
        },
      },
      lng: language, // Set language based on the provided language parameter
      fallbackLng: 'en', // Fallback language
      interpolation: {
        escapeValue: false,
      },
    });
    i18n.on('languageChanged', (lang) => {
      if (lang === 'ar') {
        // document.documentElement.setAttribute('dir', 'rtl');
        document.body.classList.add('arabic');
      } else {
        // document.documentElement.setAttribute('dir', 'ltr');
        document.body.classList.remove('arabic');
      }
    });
}

// Initialize i18n with the language from localStorage
initializei18n(localStorage.getItem('language') || 'en');

// Function to update language dynamically
export const updateLanguage = (language) => {
  i18n.changeLanguage(language); // Change the language in i18n
}

export default i18n;
